import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import AppLayout from '../components/layout/AppLayout';
import queryString from 'query-string';
import { ConsentDetails } from '../components/ui/ConsentDetails/ConsentDetails';
import { useLocation } from '@gatsbyjs/reach-router';

const blackButton = {
  backgroundColor: 'rgba(0, 0, 0, 0.87)',
  borderRadius: '30px',
  border: 0,
  color: '#FFFFFF',
  padding: '10px 20px',
  marginTop: '30px',
  alignSelf: 'center',
  justifySelf: 'center',
  textTransform: 'none',
  fontWeight: 600,
  lineHeight: 2,
  letterSpacing: '1px',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  '&:disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: '#FFFFFF',
  },
};

const ConsentForm = () => {
  const location = useLocation();
  const parsedQuery = queryString.parse(location.search);
  const [ethics, setEthics] = useState(false);
  const [terms, setTerms] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [postUrl, setPostUrl] = useState('');

  useEffect(() => {
    if (parsedQuery) {
      setPostUrl(`https://${process.env.AUTH_DOMAIN!}/continue?state=${parsedQuery.state!}`);
    }
  }, [parsedQuery]);

  return (
    <AppLayout>
      <ConsentDetails />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        component="section"
        flexDirection="column"
        m={2}
        style={{ marginBottom: '110px' }}
      >
        <form
          action={postUrl}
          method="Post"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <FormControlLabel
            control={<Checkbox checked={ethics} onChange={() => setEthics((v) => !v)} name="ethics" />}
            label="I have read and understood the Ethics Information and Consent Form"
          />
          <FormControlLabel
            control={<Checkbox checked={terms} onChange={() => setTerms((v) => !v)} name="terms" />}
            label="I have read and understood the Terms of Use"
          />
          <FormControlLabel
            control={<Checkbox checked={policy} onChange={() => setPolicy((v) => !v)} name="policy" />}
            label="I have read and understood the Privacy Policy"
          />

          <input type="checkbox" name="confirm" value="yes" checked style={{ display: 'none' }} />

          <Button type="submit" sx={blackButton} disabled={!ethics || !terms || !policy}>
            <span>I Agree and Consent</span>
          </Button>
        </form>
      </Box>
    </AppLayout>
  );
};

export default ConsentForm;
